//保证金相关接口
import { axios } from '@/utils/request'
/*
 * @Description: 
 * @Version: 
 * @Author: 彭勃
 * @Date: 2022-02-8 11:15:50
 */

//保证金列表分页查询
export function bondList(parameter){
    return axios({
        url: '/bond/page',
        method: 'post',
        params:parameter.page,
        data: parameter.queryParam
    })
}
//保证金列表详情
export function bondDetail(parameter){
    return axios({
        url: '/bond/detail',
        method: 'post',
        params: parameter 
    })
}


//保证金退款审核列表
export function bondRefundList(parameter){
    return axios({
        url: '/bond/refundPage',
        method: 'post',
        params:parameter.page,
        data: parameter.queryParam
    })
}
// 保证金退款审核
export function bondAudit(parameter){
    return axios({
        url: '/bond/audit',
        method: 'post',
        data: parameter
    })
}
//保证金退款审核记录
export function bondAuditDetail(parameter){
    return axios({
        url: '/bond/refundDetail',
        method: 'post',
        params: parameter
    })
}

//保证金总计金额
export function getBondTotalMoney(parameter){
    return axios({
        url: '/bond/totalPrice',
        method: 'post',
        data: parameter
    })
}