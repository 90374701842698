<template>
  <!-- 保证金列表页面 -->
  <div class="container">
    <a-row class="right-content-top" :gutter="10" style="margin-bottom: 10px">
      <a-col :span="8" class="header-info">
        <div class="bg-info">
          <img src="@/assets/icons/icon_04.png" alt="" />
          <div class="icon-wrapper">
            <h1>{{ topInfoList }}</h1>
            <span>保证金金额</span>
          </div>
        </div>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="24">
                <!-- 商家昵称 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="关键词">
                    <a-input v-model="queryParam.keyword" allow-clear placeholder="关键词搜索" />
                  </a-form-item>
                </a-col>
                <!-- 支付方式 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="支付方式">
                    <a-select v-model="queryParam.payType" allow-clear placeholder="请选择">
                      <a-select-option :value="1">支付宝</a-select-option>
                      <a-select-option :value="2">微信</a-select-option>
                      <a-select-option :value="3">余额</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <!-- 支付状态 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="支付状态">
                    <a-select v-model="queryParam.status" allow-clear placeholder="请选择">
                      <!-- <a-select-option :value="1">待支付</a-select-option> -->
                      <a-select-option :value="2">支付成功</a-select-option>
                      <a-select-option :value="3">支付失败</a-select-option>
                      <a-select-option :value="4">已退还</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <!-- 时间 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="时间">
                    <a-range-picker allow-clear v-model="queryParam.time" show-time style="width: 320px" />
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-button type="primary" @click="initTableData">搜索</a-button>
                  <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
                  <a-button type="primary" @click="exportListData" style="margin-left: 8px">导出</a-button>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 表格 -->
          <a-table
            :columns="columns"
            :data-source="data"
            :scroll="{ x: 1300 }"
            :loading="loading"
            :pagination="false"
            rowKey="id"
          >
            <!-- 支付方式插槽 -->
            <span slot="payType" slot-scope="text">
              {{ text == 1 ? '支付宝' : text == 2 ? '微信' : text == 3 ? '余额' : '' }}
            </span>
            <!-- 支付状态插槽 -->
            <span slot="status" slot-scope="text">
              <a-tag
                :color="text == 1 ? 'orange' : text == 2 ? 'green' : text == 3 ? 'red' : text == 4 ? 'purple' : ''"
              >
                {{ text == 1 ? '待支付' : text == 2 ? '支付成功' : text == 3 ? '支付失败' : text == 4 ? '已退还' : '' }}
              </a-tag>
            </span>
          </a-table>
          <!-- 分页组件 -->
          <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
            <a-pagination
              :pageSize="page.pageSize"
              :current="page.pageNo"
              show-size-changer
              :page-size-options="['10', '20', '50', '100']"
              show-quick-jumper
              :total="total"
              :show-total="(total) => `共 ${total} 条`"
              @change="pageChange"
              @showSizeChange="sizeChange"
            >
            </a-pagination>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { bondList, getBondTotalMoney } from '@/api/modular/mallLiving/bondMoney'

const columns = [
  {
    title: '序号',
    align: 'center',
    width: '100px',
    key: 'number',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '商家昵称',
    align: 'center',
    dataIndex: 'storeName',
    key: 'storeName',
  },
  {
    title: 'ID号',
    dataIndex: 'idNo',
    align: 'center',
  },
  {
    title: '手机号',
    dataIndex: 'mobile',
    align: 'center',
  },
  {
    title: '缴纳金额',
    dataIndex: 'price',
    key: 'price',
    align: 'center',
  },
  {
    title: '支付方式',
    dataIndex: 'payType',
    key: 'payType',
    align: 'center',
    scopedSlots: {
      customRender: 'payType',
    },
  },
  {
    title: '支付状态',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    scopedSlots: {
      customRender: 'status',
    },
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    align: 'center',
  },
]

export default {
  data() {
    return {
      columns,
      data: [],
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      total: 0,
      loading: false,
      queryParam: {
        isExport: 1,
      },
      topInfoList: 0,
    }
  },
  mounted() {
    this.initTableData()
  },
  methods: {
    exportListData() {
      if (this.queryParam.time && this.queryParam.time.length == 2) {
        this.queryParam.startTime = this.onChange(this.queryParam.time[0]._d)
        this.queryParam.endTime = this.onChange(this.queryParam.time[1]._d)
      } else {
        this.queryParam.startTime = ''
        this.queryParam.endTime = ''
      }
      this.queryParam.isExport = 2
      this.axios
        .post(`/bond/page`, this.queryParam, {
          responseType: 'blob', //服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'，默认是'json'
        })
        .then((res) => {
          if (!res) return
          const blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel',
          }) // 构造一个blob对象来处理数据，并设置文件类型

          const href = URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
          const a = document.createElement('a') //创建a标签
          a.style.display = 'none'
          a.href = href // 指定下载链接
          a.download = '保证金_' + new Date().getTime() + '.xlsx' //指定下载文件名
          a.click() //触发下载
          URL.revokeObjectURL(a.href) //释放URL对象
        })
        .catch((err) => {
          console.log(err)
        })
    },
    //切换页面
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    //时间处理函数
    onChange(date) {
      var date = new Date(date).getTime()
      return date
    },

    getList() {
      this.loading = true
      if (this.queryParam.time && this.queryParam.time.length == 2) {
        this.queryParam.startTime = this.onChange(this.queryParam.time[0]._d)
        this.queryParam.endTime = this.onChange(this.queryParam.time[1]._d)
      } else {
        this.queryParam.startTime = ''
        this.queryParam.endTime = ''
      }
      this.queryParam.isExport = 1
      var params = {
        page: this.page,
        queryParam: this.queryParam,
      }
      bondList(params)
        .then((res) => {
          if (res.success) {
            this.data = res.data.rows
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
      getBondTotalMoney(this.queryParam).then((res) => {
        if (res.success) {
          this.topInfoList = res.data
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  min-width: 1000px;
}

.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}

.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}

.right-content {
  box-sizing: border-box;
  padding: 20px;
}

.icon {
  font-size: 20px;
  margin: 0 5px;
}

img {
  width: 20px;
  height: 20px;
}
.header-info {
  .bg-info {
    background: rgba(255, 255, 255, 100);
    padding: 5px;

    img {
      width: 95px;
      height: 107px;
    }

    .icon-wrapper {
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;

      h1 {
        font-size: 23px;
        line-height: 25px;
      }
    }
  }
}
</style>
